import music from "./../Assets/music.mp3";

export const aboutCardData = {
  name: "Ravi Kumar",
  fullName: "RAJA RAVI KUMAR",
  shortName: "RRK",
  fromLocation: " Bengaluru, India.",
  role: "I am a Full Stack Developer.",
  anotherRole: "Apart from coding, some other activities that I love to do!",
  activity1: "Volunteer Work",
  activity2: "Reading Tech Blogs",
  activity3: "Gardening / Landscaping, DIY",
  activity4: "Watching Movies and Web Series",
  quote:
    "Talk to yourself once in a day, otherwise you may miss meeting an intelligent person in this world.",
  quoteWritten: "Swami Vivekananda",
};

export const homeData = {
  intro: " Hi There!",
  introLine1:
    "I fell in love with programming and I have at least learnt something, I think… 🤷‍♂️",
  introLine2: "I am fluent in classics like",
  introLine3: "ReactJS, Javascript and C/C++. ",
  introLine4: "My field of Interest's are building new",
  introLine5: " Web Technologies, Application developement and Products",
  introLine6: "Clouds.",
  introLine7:
    "Whenever possible, I also apply my passion for developing products with",
  introLine8: "Node.js",
  introLine9: "Modern Javascript Library and Frameworks",
};

export const typeWriterString = [
  "Developer",
  "Senior Software Engineer",
  "Full Stack Developer",
];

export const socialMediaUrl = {
  linkedIn: "https://www.linkedin.com/in/raja-ravi-kumar-16623320/",
  gitHub: "https://github.com/rajaravikumar/",
  facebook: "https://www.facebook.com/rajaravikumar/",
  instagram: "https://www.instagram.com/iamrajaravikumar/",
  twitter: "https://twitter.com/ravikumar_raja/",
  email: "mailto:ravikumar3210@gmail.com",
  linkedInEmbedUrl:
    "https://www.linkedin.com/feed/update/urn:li:activity:6849090055944982528/",
  linkedInEmbedUrlPost:
    "https://www.linkedin.com/posts/raja-ravi-kumar-16623320_retail-flipkart-amazon-activity-6849090055944982528-0kxH?utm_source=share&utm_medium=member_desktop",
  facebookEmbedUrl:
    "https://www.facebook.com/rajaravikumar/posts/pfbid02XkCV44jWu9budsq5pa6vjpMiTR7tZR56FZhswakm8UUEoz9A5PvvH7172WLL3ooWl",
  instagramEmbedUrl: "https://www.instagram.com/p/CRMAGFzlwYh/",
  twitterSceenName: "ravikumar_raja",
  youtbeEmbedUrl: "uLvJEQwZK2E",
};

export const contactConfig = {
  YOUR_EMAIL: "ravikumar3210@gmail.com",
  YOUR_SERVICE_ID: "service_e3s8omr",
  YOUR_TEMPLATE_ID: "template_sov7y8b",
  YOUR_USER_ID: "xlN1_99aN5SWZEka6",
};

export const resumeData = {
  summary:
    "Software Development professional with 7 years of coding experience in JavaScript technologies like React.Js, C/C++ and relevant user interface technologies including HTML5/CSS, fundamentals CI/CD, Chat.Js, Node.Js and Google Cloud Platform.",
  company: "Lowe's",
  intershipCompany: "Wechat",
  cContent1: {
    title: "Project - US Score Card",
    date: "April 2021 - present",
    content: [
      "Working as a full stack developer for Data Analysis and Customer Insights (DACI) application US Score Card.",
      "US Score Card - enable operators to fully understand business performance so that they can focus on the highest value opportunities for improvement. In order to deliver on this vision, Scorecard needs to provide operators with visibility to their financial and personal performance, root cause for the drivers and draggers of performance, and surface opportunities to improve performance that are prioritized by business value. ",
      "React.Js, Redux, React Hooks, Chat.Js, JavaScript with 2+ years experience.",
    ],
  },
  cContent2: {
    title: "Project - Store Data Lookup",
    date: "August 2019 - March 2021",
    content: [
      "Built a tool for Business/IT: Upstream and downstream applications teams can lookup Store data with single click.",
      "Designed and developed this application in React.Js/Node.Js/Google Cloud Platform (GCP).",
      "Have been exposed to HTML/CSS, JavaScript, Perl CGI Scripting, Python, React.Js, Node.Js, GCP with 1+ years experience.",
    ],
  },
  cContent3: {
    title: "Project - Genesis",
    date: "December 2016 - July 2019",
    content: [
      "Genesis is a customized UNIX application built for Lowe’s. It is a business-critical application used at all retail stores to manage point-of-sale transactions and basic retail store operations. Consequently, the application’s stability, robustness and performance are a primary focus for Lowe’s testers. ",
      "C/C++, SQL, Shell scripting with 2+ years experience.",
    ],
  },

    JContent1: {
    title: "Senior Software Engineer",
    date: "July 2023 - present",
    content: ["Full Stack Developer - ReactJS Project"],
  },
  
  JContent2: {
    title: "Senior Data Engineer",
    date: "October 2022 - present",
    content: ["Full Stack Developer - ReactJS Project"],
  },

  JContent3: {
    title: "Data Engineer",
    date: "May 2021 - September 2022",
    content: ["Full Stack Developer - ReactJS Project"],
  },

  JContent4: {
    title: "Software Engineer",
    date: "July 2018 - May 2021",
    content: ["C/C+, ReactJS, NodeJS, GCP Project"],
  },

  JContent5: {
    title: "Associate Software Engineer",
    date: "December 2016 - July 2018",
    content: ["C/C++ Project"],
  },

  eContent1: {
    title:
      "Master of Technology (MTech), Software Engineering in Birla Institute of Technology and Science, Pilani",
    date: "2017 - 2019",
    content: [
      "Gain knowledge of Technology and methods to design software more effectively.",
      "Enable software professionals to handle higher responsibilites of design, architecting, consulting, etc. Lay a foundation for research.",
      "The duration of this course is two years which are divided into four semesters. ",
    ],
  },
  eContent2: {
    title:
      "Bachelor of Engineering (BE), Computer Science in Visvesvaraya Technological University - K S School of Engineering and Management",
    date: "2012 - 2016",
    content: [
      "Bachelor of Engineering (Computer Science) is a full-time engineering undergraduate degree course in the field of computer science.",
      "It deals with the design, maintenance, construction and operation of computer hardware and software.",
      "The duration of this course is four years which are divided into eight semesters.",
    ],
  },

  eContent3: {
    title:
      "Pre-University (I &amp; II PUC), Science in Karnataka State Pre-University Board - Dayananda Sagar Institution",
    date: "2009 - 2011",
    content: [
      "PUC in Science is a combination of Physics, Chemistry, Mathematics with Biology stream. ",
      "The course equips the candidates with in-depth knowledge about the theory and practical applications of the science concepts.",
    ],
  },

  eContent4: {
    title:
      "School (1st to 10th Standard) in Karnataka State Secondary Education Examination Board - Dayananda Sagar Institution",
    date: "1999 - 2009",
    content: ["Primary and Higher Secondry School"],
  },

  iContent1: {
    title: "Test Engineer",
    date: "March 2014 - April 2014",
    content: [
      "QA Tester - Lead.",
      "Handled team of 20 resources for WeChat App testing.",
      "Preparation and Strategy Formulation.",
      "Identification of Essential Testing Types.",
      "Design of Test Script and Test Case.",
      "Usability, User Interface, Compatibility, Performance Testing.",
    ],
  },

  rContent1: {
    title: "",
    content: [
      `Team Excellence Award - Dec 2022, Issued by Lowe's India`,
      `Team Award - Dec 2021, Issued by Lowe's India`,
      `Appreciation Store GIT - Feb 2021, Issued by Lowe's India`,
      `SPOT AWARD - June 2019, Issued by Lowe's India`,
      `SPOT AWARD - July 2018, Issued by Lowe's India`,
      `Certificate of HONOUR - Mar 2015, Issued by Robosapiens Technologies`,
    ],
  },
};

export const horoscopeData = {
  weight: "5ft 5in (164cms) , 61Kg",
  dob: "24-Jan-1993 @ 8:10am",
  color: "Slim, Whitish, Fair",
  income: "Rs.27lakh",
  Siblings1: "Elder sister married and staying in Bengaluru",
  Siblings2:
    "Elder brother married - working in Honeywell as Technology Lead Engineer.",
  Siblings3: "Younger sister - working in CGI as Software Engineer.",
  contact: "+91 9342547268",
  address:
    "#39,7th Cross, Ramakrishna Nagar,J. P. Nagar Post, Bangalore 560078, Karnataka, India.",
  occupation: "Senior Software Engineer at Lowe's Companies, Inc, Bengaluru.",
};

export const horoscopeSangeethaData = {
  weight: "5ft(152.5cms) , 50Kg",
  dob: "13-Apr-1996 @ 5:43am",
  color: "Slim, Wheatish brown, Fair",
  income: "Rs.5lakh",
  Siblings1: "Elder sister married and staying in Bengaluru",
  Siblings2:
    "Elder brother married - working in Honeywell as Technology Lead Engineer.",
  Siblings3: "Elder brother married- working in Lowe's as Senior Software Engineer.",
  contact: "+91 9342547268",
  address:
    "#39,7th Cross, Ramakrishna Nagar,J. P. Nagar Post, Bangalore 560078, Karnataka, India.",
  occupation: "Software Engineer at CGI, Bengaluru.",
};

export const mediaUrl = {
  pleayerURL: music,
};

export const TRACKING_ID = "G-4EEECNLV6L"; // OUR_TRACKING_ID
