import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { CgFileDocument,  } from "react-icons/cg";
import { aboutCardData } from "./Data";
import { BsHeart } from "react-icons/bs";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <p
            style={{
              color: "#026670",
              margin: "auto",
              fontWeight: "700",
              letterSpacing: "1px",
              fontSize: "28px",
            }}
          >
            {aboutCardData.name}
          </p>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome
                  style={{ marginBottom: "2px", color: "#026670" }}
                />{" "}
                <font style={{ color: "black" }}>Home </font>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser
                  style={{ marginBottom: "2px", color: "#026670" }}
                />{" "}
                <font style={{ color: "black" }}>About </font>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument
                  style={{ marginBottom: "2px", color: "#026670" }}
                />{" "}
                <font style={{ color: "black" }}>Resume </font>
              </Nav.Link>
            </Nav.Item>

          {/*  <Nav.Item>
              <Nav.Link
                as={Link}
                to="/invitation"
                onClick={() => updateExpanded(false)}
              >
                <BsHeart
                  style={{ marginBottom: "2px", color: "#026670" }}
                />{" "}
                <font style={{ color: "black" }}>Invitation </font>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
