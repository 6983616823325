import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillFacebook,
  AiOutlineMail,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { aboutCardData, socialMediaUrl, mediaUrl } from "./Data";
// import Player from "./Player/player";
// import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Designed and Developed by {aboutCardData.shortName}</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          {" "}
{/*<h3>
            <Player url={mediaUrl.pleayerURL} />
          </h3> */}
        </Col>
        { window.location.hash.includes('sangeetha') ? '' : <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href={socialMediaUrl.linkedIn}
                style={{ color: "#026670" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>

            <li className="social-icons">
              <a
                href={socialMediaUrl.gitHub}
                style={{ color: "#026670" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>

            <li className="social-icons">
              <a
                href={socialMediaUrl.facebook}
                style={{ color: "#026670" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillFacebook />
              </a>
            </li>

            <li className="social-icons">
              <a
                href={socialMediaUrl.instagram}
                style={{ color: "#026670" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>

            <li className="social-icons">
              <a
                href={socialMediaUrl.twitter}
                style={{ color: "#026670" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>

            <li className="social-icons">
              <a
                href={socialMediaUrl.email}
                style={{ color: "#026670" }}
                rel="noopener noreferrer"
              >
                <AiOutlineMail />
              </a>
            </li>
          </ul>
        </Col>}
      </Row>
    </Container>
  );
}

export default Footer;
